import React from "react";
import {Link} from 'gatsby';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav' ;
import Logo from '../../static/vms_dtp_logo.svg';
import '../../static/header.css';




 const Header = () => <div>
  
   <Navbar collapseOnSelect expand="md" bg="light" variant="light">
  <Navbar.Brand href="/">
      <img
        src={Logo}
        width="191"
        height="49.5"
        className="d-inline-block align-top"
        alt="vms dtp logo"
      />
    </Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="ml-auto">
    {/* <Nav.Link href="http://localhost:8000">Home</Nav.Link> */}
    <Nav.Link href="/">  Home</Nav.Link>
           <Nav.Link href="/services">Services</Nav.Link>
      <Nav.Link href="/contact"> Contact  </Nav.Link>
      
    </Nav>
  </Navbar.Collapse>
</Navbar>
 
  <br/>
   
    </div>
export default Header;